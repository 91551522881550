<script>
import {
  SuawMainContent,
  SuawSummary,
  SuawTextInput,
  SuawInputGroup,
  SuawFileUpload,
  SuawLabel,
  SuawRadio,
  SuawDropdown,
  SuawButton,
  SuawForm,
  SuawTextArea
} from "@/components";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import CircleStencil from "@/components/CircleStencil.vue";
import { required, maxLength } from "vuelidate/lib/validators";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import * as UserApi from "../../api.js";
import { getAvatarColorOptions } from "../../common.js";
import { UserSettingsById } from "../../operations.gql";
export default {
  name: "DashboardProfileEdit",
  components: {
    SuawMainContent,
    SuawTextInput,
    SuawSummary,
    SuawInputGroup,
    SuawFileUpload,
    SuawLabel,
    SuawRadio,
    SuawDropdown,
    SuawButton,
    SuawForm,
    SuawTextArea,
    Cropper
  },
  data() {
    return {
      avatarColorOptions: getAvatarColorOptions(),
      showCropper: false,
      cropperImage: null,
      resultUserSettingsById: {
        id: "",
        email: "",
        first_name: "",
        last_name: "",
        is_last_name_hidden: null,
        country: "",
        country_abbrev: "",
        postal_code: "",
        unit_system: "",
        description_json: null,
        avatar_color: "",
        languages: null,
        city: "",
        region: "",
        is_preferred_chapter_discussions: [
          // {
          //   is_preferred: null
          // }
        ],
        is_preferred_series_discussions: [
          // {
          //   is_preferred: null
          // }
        ]
      },
      editForm: {
        userId: "",
        firstName: "",
        lastName: "",
        isLastNameHidden: null,
        countryAbbrev: "",
        postalCode: "",
        unitSystem: "",
        descriptionJson: { type: "doc", content: [{ type: "paragraph" }] },
        avatarColor: "",
        languages: null,
        city: null,
        region: null,
        avatarUrl: "",
        avatarType: "color"
      },
      croppedPreviewUrl: null
    };
  },
  apollo: {
    resultUserSettingsById: {
      query: UserSettingsById,
      variables() {
        return {
          user_id: this.$auth.user.id
        };
      },
      update(data) {
        if (data && data.resultUserSettingsById && data.resultUserSettingsById.length > 0) {
          this.populateEditForm(data.resultUserSettingsById[0]);
        }
        return data.resultUserSettingsById?.[0] || this.resultUserSettingsById;
      },
      fetchPolicy: "no-cache",
      skip() {
        return !this.$auth.isAuthenticated;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  validations: {
    editForm: {
      firstName: {
        required,
        maxLength: maxLength(40)
      },
      lastName: {
        required,
        maxLength: maxLength(40)
      }
    }
  },
  computed: {
    originalFirstName() {
      return this.$auth.user.first_name;
    },
    originalLastName() {
      return this.$auth.user.last_name;
    },
    showImageUpload() {
      return this.editForm.avatarType === "image";
    },
    circleStencilComponent() {
      return CircleStencil;
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    populateEditForm(userData) {
      this.editForm = {
        userId: userData.id ?? "",
        firstName: userData.first_name ?? "",
        lastName: userData.last_name ?? "",
        isLastNameHidden: userData.is_last_name_hidden ?? null,
        countryAbbrev: userData.country_abbrev ?? "",
        postalCode: userData.postal_code ?? "",
        unitSystem: userData.unit_system ?? "",
        descriptionJson: userData.description_json ?? null,
        avatarColor: userData.avatar_color ?? "",
        avatarType: this.$auth.user.avatar_url ? "image" : "color",
        languages: userData.languages ?? null,
        city: userData.city ?? "",
        region: userData.region ?? ""
      };
    },
    async uploadUserAvatar() {
      const { userId, avatarUrl } = this.editForm;
      const result = await UserApi.uploadUserAvatar(userId, avatarUrl);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      } else {
        this.$root.$emit("universal-avatar-updated", userId);
      }
      return result;
    },
    async updateUserProfile() {
      const { userId, firstName, lastName, isLastNameHidden, countryAbbrev, postalCode, unitSystem, descriptionJson, avatarColor, languages, city, region } = this.editForm;
      const result = await UserApi.updateUserProfile(
        userId,
        firstName,
        lastName,
        isLastNameHidden,
        countryAbbrev,
        postalCode,
        unitSystem,
        descriptionJson,
        avatarColor,
        languages,
        city,
        region
      );
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    onCancelClicked() {
      this.$router.push({ name: "DashboardProfileView" });
    },
    async onConfirmClicked() {
      this.$v.editForm.$touch();
      if (this.$v.editForm.$invalid) {
        this.$root.$emit("universal-error-message", "Please complete required fields of form.");
      } else {
        if (this.editForm.avatarUrl) {
          const uploadUserAvatarResult = await this.uploadUserAvatar();
          if (!uploadUserAvatarResult.success) {
            return;
          }
        }

        const updateUserProfileResult = await this.updateUserProfile();
        if (!updateUserProfileResult.success) {
          return;
        }

        try {
          this.$router.push({ name: "DashboardProfileView" });
          this.$root.$emit("universal-success-message", "You successfully updated your profile!");
        } catch (error) {
          this.$root.$emit("universal-error-message", "Unable to update your profile. Try again or contact support if issues persist."); //!!not exactly true though is it?
        }
      }
    },
    async onFileSelected(file) {
      try {
        const reader = new FileReader();
        reader.onload = e => {
          this.cropperImage = e.target.result;
          this.showCropper = true;
        };
        reader.onerror = error => {
          this.$root.$emit("universal-error-message", "FileReader error: " + error);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        this.$root.$emit("universal-error-message", "Error processing image: " + error);
      }
    },

    cancelCrop() {
      this.showCropper = false;
      this.cropperImage = null;
      this.croppedPreviewUrl = null;
    },

    onResizeStart() {
      // Just a placeholder for the event
    },

    onResize(event) {
      if (event.directions) {
        // The directions have already been fixed in the CircleStencil component
        // We don't need to modify them further
        return;
      }

      // Default handling for standard resize events
      const size = Math.min(event.width, event.height);
      event.width = size;
      event.height = size;
    },

    onResizeEnd() {
      // Just a placeholder for the event
    },

    completeCrop() {
      try {
        const result = this.$refs.cropper.getResult({
          size: "stencil",
          fillColor: null
        });

        if (result?.canvas) {
          const canvas = result.canvas;
          const ctx = canvas.getContext("2d");
          const size = canvas.width;

          // Apply circular mask
          ctx.globalCompositeOperation = "destination-in";
          ctx.beginPath();
          ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
          ctx.closePath();
          ctx.fill();

          // Get the cropped canvas as a base64 string
          const croppedResult = canvas.toDataURL("image/jpeg");
          // Convert data URL to base64 string (remove the prefix)
          const base64Data = croppedResult.split(",")[1];
          this.editForm.avatarUrl = base64Data;
          this.croppedPreviewUrl = croppedResult;
          this.showCropper = false;
        } else {
          this.$root.$emit("universal-error-message", "No canvas returned from cropper");
        }
      } catch (error) {
        this.$root.$emit("universal-error-message", "Error in completeCrop: " + error);
      }
    },
    handleAvatarColorSelect(avatar) {
      this.editForm.avatarColor = avatar.avatarType;
    },
    redirectToSignIn() {
      this.$router.push({
        name: "LogIn",
        query: {
          redirectUrl: `${this.$route.fullPath}`
        }
      });
    }
  }
};
</script>

<template>
  <SuawMainContent size="medium">
    <SuawForm v-bind="$props">
      <template #form>
        <SuawSummary summary-type="text" heading="Edit Profile" />
        <SuawTextInput
          v-model="editForm.firstName"
          type="text"
          :placeholder="originalFirstName"
          label="First name"
          label-weight="bold"
          is-required
          :state="formFieldState($v, 'editForm', 'firstName')"
          :error-message="validateErrors($v.editForm.firstName, 'First name')"
          @blur="$v.editForm.firstName.$touch()"
        />
        <SuawTextInput
          v-model="editForm.lastName"
          type="text"
          :placeholder="originalLastName"
          label="Last name"
          label-weight="bold"
          is-required
          :state="formFieldState($v, 'editForm', 'lastName')"
          :error-message="validateErrors($v.editForm.lastName, 'Last name')"
          @blur="$v.editForm.lastName.$touch()"
        />
        <SuawInputGroup direction="column" group-gap="base">
          <SuawLabel label-text="Show As" size="small" weight="bold" />
          <SuawInputGroup direction="row" field-one-size="1" field-two-size="10">
            <SuawRadio id="full" v-model="editForm.isLastNameHidden" name="chooseName" label="Full Name" :native-value="false" />
            <SuawRadio id="firstLast" v-model="editForm.isLastNameHidden" name="chooseName" label="First Name & Last Initial" :native-value="true" />
          </SuawInputGroup>
        </SuawInputGroup>
        <SuawTextArea id="eventDesc" v-model="editForm.descriptionJson" label="About you" label-weight="bold" placeholder="Your Bio" use-tip-tap />
        <SuawInputGroup direction="column" group-gap="half">
          <SuawLabel label-text="Color" size="small" weight="bold" />
          <SuawDropdown
            v-model="editForm.avatarColor"
            class-name="dropdown--full-width"
            dropdown-button-size="medium"
            :items="avatarColorOptions"
            dropdown-type="mutable"
            button-type="ghost-outline"
            button-class-name="suaw-dropdown__button--fill"
            label="Avatar Color"
            @item-click="handleAvatarColorSelect"
          />
        </SuawInputGroup>
        <SuawInputGroup direction="column" group-gap="half">
          <SuawLabel label-text="Profile picture (optional)" size="small" weight="bold" />
          <SuawFileUpload upload-title="Upload Profile Picture" @file-selected="onFileSelected" />
          <div v-if="croppedPreviewUrl" class="preview-container">
            <SuawLabel label-text="Preview" size="small" weight="bold" />
            <img :src="croppedPreviewUrl" class="preview-image" />
          </div>
        </SuawInputGroup>
      </template>
    </SuawForm>
    <SuawInputGroup field-one-size="0" field-two-size="0" :centered="true">
      <SuawButton size="large" type="secondary-outline" button-text="Cancel" @click="onCancelClicked" />
      <SuawButton size="large" type="primary" button-text="Confirm Changes" @click="onConfirmClicked" />
    </SuawInputGroup>

    <!-- Image cropper modal -->
    <div v-if="showCropper" class="cropper-modal">
      <div class="cropper-container">
        <Cropper
          ref="cropper"
          class="cropper"
          image-restriction="stencil"
          :src="cropperImage"
          :stencil-component="circleStencilComponent"
          :stencil-props="{
            aspectRatio: 1,
            movable: true,
            resizable: true,
            stencilSize: { width: 300, height: 300 }
          }"
          @resize-start="onResizeStart"
          @resize="onResize"
          @resize-end="onResizeEnd"
        />
        <div class="cropper-actions">
          <SuawButton type="secondary-outline" button-text="Cancel" @click="cancelCrop" />
          <SuawButton type="primary" button-text="Crop" class="crop-button" @click="completeCrop" />
        </div>
      </div>
    </div>
  </SuawMainContent>
</template>

<style scoped>
.preview-container {
  margin-top: 20px;
  min-height: 150px;
}

.preview-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ccc;
}

.preview-placeholder {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #999;
  text-align: center;
  padding: 10px;
}

.cropper-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  overflow-y: auto;
}

.cropper-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
}

.cropper {
  width: 100%;
  height: auto;
  max-height: 400px;
  margin-bottom: 20px;
  transform: translateZ(0);
}

.cropper-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

:deep(.vue-advanced-cropper__foreground) {
  background: transparent !important;
  opacity: 0 !important;
  content: none !important;
  display: none !important;
}

.crop-button {
  background-color: #376BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.crop-button:hover {
  background-color: #2f5ee0;
}
</style>
